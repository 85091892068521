import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { linkResolver } from './src/utils/previewResolver';

import HomeBlogPost from './src/templates/home/BlogPost';
import News from './src/components/InTheNews/InTheNews';
import TeamMember from './src/templates/home/TeamMember';
import Properties from './src/components/home/Properties/Properties';
import Banner from './src/components/home/Banner/Banner';
import Services from './src/components/home/Services/Services';
import IndexPage from './src/pages/index';
import TeamPage from './src/templates/home/team';

// ARBOL
import UnitPage from './src/templates/arbol/unit';
import TheNeighborhood from './src/components/arbol/TheNeighborhood/TheNeighborhood';
import ArbolBlogPost from './src/templates/arbol/BlogPost';

// ENVY
import EnvyUnitPage from './src/templates/envy/unit';
import EnvyTheNeighborhood from './src/components/envy/TheNeighborhood/TheNeighborhood';
import EnvyBlogPost from './src/templates/envy/BlogPost';

// OLEA
// import OleaUnitPage from './src/templates/olea/unit';

const Wrapper = (props) => {
  // eslint-disable-next-line react/prop-types
  const { page_selection } = props.data.prismicNewsPost.data;

  if (page_selection === 'Arbol') {
    return <ArbolBlogPost { ...props } />;
  }

  if (page_selection === 'Envy') {
    return <EnvyBlogPost { ...props } />;
  }

  return <HomeBlogPost { ...props } />;
};


export const config = {
  repositoryName: "bygomes",
  linkResolver,
  componentResolver: componentResolverFromMap({
    unit: UnitPage,
    neighborhood_place: TheNeighborhood,
    envy_unit: EnvyUnitPage,
    // olea_unit: OleaUnitPage,
    envy_neighborhood_place: EnvyTheNeighborhood,
    news_post: Wrapper,
    in_the_news: News,
    team_member: TeamMember,
    property: Properties,
    homepage_slider: Banner,
    services: Services,
    homepage_about: IndexPage,
    team_page: TeamPage
  }),
};
